export const goal =
  "Democratize AI and make it easily accessible to our clients at a fraction of the cost and a faster time to market compared to current approaches."

export const what_we_build =
  "We build intelligent applications that incorporate industry, functional processes and engineering know-how into AI applications that can be deployed >10X faster than typical consulting engagements."

export const who_are_We =
  "We are an enterprise AI solution provider that helps businesses do more with less. Better outcomes, with lesser resources and carbon footprint. We build AI to better manage the Internet of Things (IoT) and power responsible enterprises."

export const our_team =
  "We are a team of people who are passionate about AI, IoT and Big Data and who love their respective crafts. The founding members are leaders from Accenture and EY where they incubated and scaled AI practices. The Management Team has a successful track record of delivering successful AI and IoT projects across APAC and building analytics businesses that were very successful commercially. Our AI team members have several research papers and patents in the field."

export const about_our_products =
  "Our products are elegantly and beautifully designed not just on the outside, but also on the inside, creating a User Experience that both Users and Enterprises love."
